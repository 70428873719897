import React from 'react'
import { MDBView } from 'mdbreact'
import { isMobile } from '../utils/helpers.js'

class Hero extends React.Component {
  render() {
    const bgImage = isMobile() ? `none` : `linear-gradient(to right, rgba(0,0,0,0.6) 30%, rgba(0,0,0,0.2) 100%), url('${this.props.image}')`

    return (
      <div
        className={this.props.class}
        style={{backgroundImage: bgImage, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}
      >
        <MDBView>
        </MDBView>
      </div>
    )
  }
}

export default Hero